// 其他
const data = [
  {
    name: "PicGo",
    system: ["Win", "Mac", "Linux"],
    download: "https://picgo.github.io/PicGo-Doc/zh/",
    icon: "png",
    state: "gnu",
    msg: "图片上传到云存储作图床用",
  },
  {
    name: "Eagle",
    system: ["Win", "Mac"],
    download: "https://eagle.cool/",
    icon: "svg",
    msg: "轻松收集及整理设计项目的各种素材",
  },
  {
    name: "Billfish",
    system: ["Win", "Mac"],
    download: "https://www.billfish.cn/",
    icon: "png",
    state: "free",
    msg: "快速、轻松的管理素材",
  },
  {
    name: "Pixcall",
    system: ["Win", "Mac", "Web"],
    download: "https://pixcall.com/",
    icon: "svg",
    msg: "云端管理设计素材，支持多种同步方案",
  },
  {
    name: "比特虫",
    download: "https://www.bitbug.net/",
    system: ["Web"],
    icon: "ico",

    msg: "在线绘制ICO图标",
    state: "free",
  },
  {
    name: "waifu2x",
    system: ["Web"],
    download: "https://waifu2x.udp.jp/",
    icon: "git",

    state: "gnu",
    msg: "无损放大动漫图片",
  },

  {
    name: "Upscayl",
    system: ["Win", "Mac", "Linux"],
    download: "https://www.upscayl.org/",
    icon: "png",
    state: "gnu",
    msg: "AI 无损放大图片",
  },
  {
    name: "DimmyClub",
    system: ["Web"],
    download: "https://dimmy.club/",
    icon: "png",
    state: "free",
    msg: "苹果设备样机",
  },
  {
    name: "Honeyview",
    system: ["Win"],
    download: "https://cn.bandisoft.com/honeyview/",
    icon: "jpg",
    state: "free",
    msg: "小巧图片查看器",
  },
];

const newArr = {
  type: "其他",
  path: "about",
  data: data,
  describe: "还没想好怎么分类",
};

export default newArr;
