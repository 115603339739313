//文章链接
export const singleLink = [
  {
    id: 1,
    title: "怎么给win10多系统设置默认的启动系统",
    link: "https://www.bkqs.com.cn/content/zpe4qz7ny.html",
    type: "系统",
  },
  {
    id: 2,
    title: "win11系统第一次开机跳过联网的办法",
    link: "https://blog.csdn.net/jaray/article/details/130722929",
    type: "系统",
  },
  {
    id: 3,
    title: "Windows10 切换用户访问共享",
    link: "https://www.cnblogs.com/Yogile/p/12589615.html",
    type: "NAS",
  },
  {
    id: 4,
    title: "微信多开",
    link: "https://www.bilibili.com/read/cv6259988/",
    type: "教程",
  },
  {
    id: 5,
    title: "Win10系统中取消本地账户密码的方法",
    link: "https://blog.csdn.net/xzji001/article/details/134201141",
    type: "教程",
  },
  {
    id: 6,
    title: "华为电脑驱动解决办法",
    link: "https://consumer.huawei.com/cn/support/content/zh-cn00688502/",
    type: "驱动",
  },
  {
    id: 7,
    title: "安装系统驱动方法",
    link: "https://zhidao.baidu.com/question/2202828209592209668/answer/2336475000.html",
    type: "驱动",
  },
];

//教程
export const postList = [
  {
    title: "win11 跳过联网激活",
    msg: ` 按下【Shift+F10】快捷键（无效可试下【FN+Shfit+F10】），
    在出现的命令提示符页面输入OOBE\\BYPASSNRO，按回车键，等待电脑重启完成"`,
  },
  {
    title: "修改Windows用户名",
    msg: "win+R,运行‘netplwiz’",
  },
  {
    title: "修改网盘",
    msg: "搜索“凭据”",
  },
  {
    title: "双开微信",
    msg: "鼠标点击一次微信图标，双击两次键盘上的回车键（Enter）即可”",
  },
  {
    title: "双开微信2",
    msg: 'start "" "D:\\Program Files\\WeChat\\WeChat.exe"',
  },
];
